<template>
  <div id="employee-edit-access-permission-tab">
    <div v-if="systemAccess">
      <vs-tabs id="access-permission-tab" position="left" color="primary">
        <vs-tab label="Atendimento">
          <atendimento-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <vs-tab label="Agendas">
          <schedule-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <vs-tab label="Financeiro">
          <financial-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <vs-tab label="Relatórios">
          <report-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <vs-tab label="Mala Direta">
          <mail-marketing-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <vs-tab label="Cadastros">
          <registration-tab :permissions="permissions" :employee="employee" />
        </vs-tab>
        <!-- <vs-tab label="Configurações">
          <configuration-tab :permissions="permissions" :employee="employee" />
        </vs-tab> -->
      </vs-tabs>
    </div>
    <vs-alert v-if="!systemAccess" class="mt-2 mb-4" color="primary" title="Funcionário sem acesso ao sistema:" :active="!systemAccess" icon-pack="feather">
      <p>Este funcionário não tem acesso ao sistema.</p>
      <p>Para definir as permissões de acesso a ele, primeiramente conceda acesso ao sistema</p>
      <p>e depois retorne a esta página para configurar as permissões.</p>
    </vs-alert>
  </div>
</template>

<script>
import AtendimentoTab   from './tabs/atendimento/Atendimento.vue'
import ScheduleTab      from './tabs/schedule/Schedule.vue'
import FinancialTab     from './tabs/financial/Financial.vue'
import ReportTab        from './tabs/report/Report.vue'
import MailMarketingTab from './tabs/mail-marketing/MailMarketing.vue'
import RegistrationTab  from './tabs/registration/Registration.vue'
import ConfigurationTab from './tabs/configuration/Configuration.vue'

export default {
  components: {
    AtendimentoTab,
    ScheduleTab,
    FinancialTab,
    ReportTab,
    MailMarketingTab,
    RegistrationTab,
    ConfigurationTab
  },
  data () {
    return {
      permissions: [],
      systemAccess: false,
      employee: {}
    }
  },
  mounted () {
    this.employee = JSON.parse(JSON.stringify(this.$store.getters['employee/get']))
    this.$http.get(`${process.env.VUE_APP_API_URL}/permissions`)
      .then((response) => {
        this.permissions = response.data
      })
      .catch((error)   => { console.log(error) })

    if (this.employee.user) {
      this.$nextTick(() => { this.systemAccess = true })
    }
  }
}
</script>

<style>
#employee-edit-access-permission-tab .vs-tabs--li button.vs-tabs--btn {
  text-align: end;
}
#employee-edit-access-permission-tab .vs-tabs--content {
  padding: 0px 10px !important;
}
</style>
