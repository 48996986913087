var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "atendimento" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Dados do CFC"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_cfc_register"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_cfc_register`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_cfc_register"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value:
                                  _vm.modules.spa_configuration_cfc_register,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_cfc_register",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_configuration_cfc_register",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Geral"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_general"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_general`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_general"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value: _vm.modules.spa_configuration_general,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_general",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_configuration_general",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("E-mail"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_automatic_email"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_automatic_email`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_automatic_email"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value:
                                  _vm.modules.spa_configuration_automatic_email,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_automatic_email",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_configuration_automatic_email",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Horários"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_hours"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_hours`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_hours"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value: _vm.modules.spa_configuration_hours,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_hours",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_configuration_hours",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Plano de Contas"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_account_plans"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_account_plans`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_account_plans"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value:
                                  _vm.modules.spa_configuration_account_plans,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_account_plans",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_configuration_account_plans",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Serviços"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_configuration_services"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_configuration_services`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_configuration_services"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "update" },
                              model: {
                                value: _vm.modules.spa_configuration_services,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_configuration_services",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_configuration_services",
                              },
                            },
                            [_vm._v("Alterar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }