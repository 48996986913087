var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-row" },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup40",
          attrs: {
            fullscreen: false,
            title: "Cadastrar / Editar Grupo",
            active: _vm.popupFormGroup,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupFormGroup = $event
            },
          },
        },
        [
          _c("form-group", {
            on: {
              saved: function ($event) {
                _vm.popupFormGroup = false
              },
            },
            model: {
              value: _vm.groupEditing,
              callback: function ($$v) {
                _vm.groupEditing = $$v
              },
              expression: "groupEditing",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-2/5" },
        [
          _c(
            "div",
            { staticClass: "inline-flex" },
            [
              _c("vs-button", { on: { click: _vm.openFormToCreateGroup } }, [
                _vm._v("Cadastrar"),
              ]),
            ],
            1
          ),
          _c("vs-divider"),
          _c(
            "vs-table",
            {
              attrs: { data: _vm.groups, noDataText: "Sem dados" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (group, idx) {
                      return _c(
                        "vs-tr",
                        {
                          key: idx,
                          attrs: {
                            state:
                              group.name === _vm.selected.name ? "success" : "",
                          },
                        },
                        [
                          _c("vs-td", [
                            _c(
                              "div",
                              { staticClass: "inline-flex" },
                              [
                                _c("feather-icon", {
                                  staticClass: "text-primary",
                                  attrs: {
                                    icon: "CheckSquareIcon",
                                    svgClasses:
                                      "h-4 hover:text-primary cursor-pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editGroupPermission(group)
                                    },
                                  },
                                }),
                                _c("feather-icon", {
                                  staticClass: "text-primary",
                                  attrs: {
                                    icon: "EditIcon",
                                    svgClasses:
                                      "h-4 hover:text-primary cursor-pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editGroup(group)
                                    },
                                  },
                                }),
                                _c("feather-icon", {
                                  staticClass: "text-primary",
                                  attrs: {
                                    icon: "Trash2Icon",
                                    svgClasses:
                                      "h-4 hover:text-primary cursor-pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeGroup(group)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(group.name) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "header" }, [
                _c("h3", { staticClass: "text-primary" }, [
                  _vm._v("Lista de grupos"),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-3/5" },
        [
          _c("list-permission", {
            attrs: { title: _vm.selected.name, "group-id": _vm.selected.id },
            model: {
              value: _vm.selected.permissions,
              callback: function ($$v) {
                _vm.$set(_vm.selected, "permissions", $$v)
              },
              expression: "selected.permissions",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }