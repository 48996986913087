var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "atendimento" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Aula Prática"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_practical"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_practical`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_practical"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_schedule_practical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_practical",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_schedule_practical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_practical",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_schedule_practical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_practical",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Aula Teórica"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_theoretical"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_theoretical`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_theoretical"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_schedule_theoretical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_theoretical",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_schedule_theoretical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_theoretical",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_schedule_theoretical,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_theoretical",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Compromissos"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_commitment"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_commitment`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_commitment"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_schedule_commitment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_commitment",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_commitment",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_schedule_commitment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_commitment",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_commitment",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_schedule_commitment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_commitment",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_commitment",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Exame Médico"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_medical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_medical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_medical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_schedule_medical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_medical_exam",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_medical_exam",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_schedule_medical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_medical_exam",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_medical_exam",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_schedule_medical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_medical_exam",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_schedule_medical_exam",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Exame Psicotécnico"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_psychotechnical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_psychotechnical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_psychotechnical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_psychotechnical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_psychotechnical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_psychotechnical_exam",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_psychotechnical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_psychotechnical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_psychotechnical_exam",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_psychotechnical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_psychotechnical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_psychotechnical_exam",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Exame Teórico"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_theoretical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_theoretical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_theoretical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_theoretical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_theoretical_exam",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_theoretical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_theoretical_exam",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value:
                                  _vm.modules.spa_schedule_theoretical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_theoretical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_theoretical_exam",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Exame Prático"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_schedule_practical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_schedule_practical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_schedule_practical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_schedule_practical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_practical_exam",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_schedule_practical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_practical_exam",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_schedule_practical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_schedule_practical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_schedule_practical_exam",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }