<template>
  <div id="atendimento">
    <vs-collapse accordion type="margin">
      <vs-collapse-item :open="false">
        <div slot="header">Aula Prática</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_practical')" :key="`${i}_spa_schedule_practical`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_practical')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Aula Teórica</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_theoretical')" :key="`${i}_spa_schedule_theoretical`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_theoretical')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Compromissos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_commitment')" :key="`${i}_spa_schedule_commitment`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_commitment')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_commitment" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_commitment" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_commitment" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Exame Médico</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_medical_exam')" :key="`${i}_spa_schedule_medical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_medical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_medical_exam" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_medical_exam" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_medical_exam" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Exame Psicotécnico</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_psychotechnical_exam')" :key="`${i}_spa_schedule_psychotechnical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_psychotechnical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_psychotechnical_exam" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_psychotechnical_exam" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_psychotechnical_exam" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Exame Teórico</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_theoretical_exam')" :key="`${i}_spa_schedule_theoretical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_theoretical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical_exam" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical_exam" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_theoretical_exam" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Exame Prático</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_practical_exam')" :key="`${i}_spa_schedule_practical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_practical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical_exam" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical_exam" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_practical_exam" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <!-- <vs-collapse-item :open="false">
        <div slot="header">IBio</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_schedule_ibio')" :key="`${i}_spa_schedule_ibio`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_schedule_ibio')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_ibio" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_ibio" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_schedule_ibio" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item> -->
    </vs-collapse>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="mb-4" @click="save">Salvar Alterações</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissions: { type: Array, required: true },
    employee: { type: Object, required: true }
  },
  data () {
    return {
      modules: {
        spa_schedule_practical: [],
        spa_schedule_theoretical: [],
        spa_schedule_commitment: [],
        spa_schedule_medical_exam: [],
        spa_schedule_psychotechnical_exam: [],
        spa_schedule_theoretical_exam: [],
        spa_schedule_practical_exam: [],
        spa_schedule_ibio: []
      }
    }
  },
  methods: {
    changeChecked (m) {
      if (this.modules[m].length) this.modules[m] = []
      else this.modules[m] = ['read', 'save', 'delete']
    },
    async save () {
      try {
        await this.$http.put(`${process.env.VUE_APP_API_URL}/user/permission/update/${this.employee.id}/`, this.modules)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    // console.log(this.employee.user.permissions_user)
    this.employee.user.permissions_user.map(a => {
      this.modules[a.module] = JSON.parse(a.pivot.permissions)
    })
  }
}
</script>

<style>
#atendimento .vs-collapse {
  padding: 0px !important;
}
</style>
