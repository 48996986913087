<template>
  <div>
    <h3 class="text-center text-primary" v-if="title">
      Permissões - {{ title }}
    </h3>
    <vs-tabs id="access-permission-tab" position="left" color="primary">
      <vs-tab :label="item.name" v-for="(item, idx) in modules" :key="idx">
        <vs-collapse accordion type="margin">
          <vs-collapse-item v-for="(subItem, idz) in item.items" :key="idz">
            <div slot="header">{{ subItem.name }}</div>
            <div>
              <div v-for="(resource, idw) in subItem.resources" :key="idw">
                <vs-divider>{{ resource.name }}</vs-divider>
                <div class="inline-flex">
                  <div  v-for="(rule, idy) in resource.permissions" @click="checkGroupPermission(rule.value)" :key="idy">
                      <vs-checkbox
                    v-model="permissions"
                    :vs-value="rule.value"
                    :disabled="groupsPermissions.includes(rule.value)"
                  >
                    {{ rule.name }}
                  </vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vs-tab>
    </vs-tabs>
      <vs-button @click="savePermissions">Salvar</vs-button>
  </div>
</template>

<script>
import { modules } from './permissions'
import axios from '@/axios'

export default {
  props: {
    title:{
      default: false
    },
    value:{},
    userId: {},
    groupId: {},
    groups: {}
  },
  data () {
    return {
      modules
    }
  },
  computed: {
    permissions: {
      get () {
        return (this.value || []).concat(this.groupsPermissions)
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    groupsPermissions () {
      if (this.groups) return this.$store.state.group.groups.filter(e => this.groups.map(d => d.id).includes(e.id)).map(e => e.permissions).flat()
      return []
    }
  },
  methods: {
    savePermissions () {
      const payload = {
        permissions: this.permissions.filter(e => !this.groupsPermissions.includes(e))
      }
      if (this.groups) {
        payload.groups = this.groups.map(e => e.id)
      }
      if (this.userId) payload.user_id = this.userId
      else payload.group_id = this.groupId

      this.$vs.loading()
      axios.post(`${process.env.VUE_APP_API_URL}/general/permission`, payload)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
          this.$store.dispatch('group/fetchAll')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    checkGroupPermission (rule) {
      if (this.groupsPermissions.includes(rule)) this.mixinNotify('Permissão herdada de grupo não pode ser alterada', 'warning')
    }
  }
}
</script>

