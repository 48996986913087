<template>
  <div id="atendimento">
    <vs-collapse accordion type="margin">

      <vs-collapse-item :open="false">
        <div slot="header">Dados do CFC</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_cfc_register')" :key="`${i}_spa_configuration_cfc_register`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_cfc_register')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_cfc_register" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Geral</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_general')" :key="`${i}_spa_configuration_general`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_general')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_general" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">E-mail</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_automatic_email')" :key="`${i}_spa_configuration_automatic_email`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_automatic_email')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_automatic_email" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Horários</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_hours')" :key="`${i}_spa_configuration_hours`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_hours')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_hours" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Plano de Contas</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_account_plans')" :key="`${i}_spa_configuration_account_plans`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_account_plans')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_account_plans" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Serviços</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_configuration_services')" :key="`${i}_spa_configuration_services`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_configuration_services')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_configuration_services" vs-value="update" class="mr-4 mb-2">Alterar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="mb-4" @click="save">Salvar Alterações</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissions: { type: Array, required: true },
    employee: { type: Object, required: true }
  },
  data () {
    return {
      modules: {
        spa_configuration_cfc_register: [],
        spa_configuration_general: [],
        spa_configuration_automatic_email: [],
        spa_configuration_hours: [],
        spa_configuration_account_plans: [],
        spa_configuration_services: []
      }
    }
  },
  methods: {
    changeChecked (m) {
      if (this.modules[m].length) this.modules[m] = []
      else this.modules[m] = ['update']
    },

    async save () {
      try {
        await this.$http.put(`${process.env.VUE_APP_API_URL}/user/permission/update/${this.employee.id}/`, this.modules)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    // console.log(this.employee.user.permissions_user)
    this.employee.user.permissions_user.map(a => {
      this.modules[a.module] = JSON.parse(a.pivot.permissions)
    })
  }
}
</script>

<style>
#atendimento .vs-collapse {
      padding: 0px !important;
}
</style>
