<template>
  <div id="employee-edit-time-schedules-tab">

    <h5 class="p-3">Horários das Agendas</h5>
    <vs-table id="table_" noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th>Período</vs-th>
        <vs-th>Início</vs-th>
        <vs-th>Fim</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">Matutino</span></vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.matutino.start" class="w-full" label="" />
          </vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.matutino.end" class="w-full" label="" />
          </vs-td>
        </vs-tr>
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">Vespertino</span></vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.vespertino.start" class="w-full" label="" />
          </vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.vespertino.end" class="w-full" label="" />
          </vs-td>
        </vs-tr>
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">Noturno</span></vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.noturno.start" class="w-full" label="" />
          </vs-td>
          <vs-td style="text-align:center">
            <vs-input type="time" @change="debouncedSave" :disabled="!configs.schedule.timesForCompany" v-model="configs.schedule.period.noturno.end" class="w-full" label="" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <h5 class="p-3">Intervalos</h5>
    <vs-table noDataText="" :data="configs.schedule.intervals" hoverFlat stripe style="overflow: -webkit-paged-y">
      <template slot="thead">
        <vs-th>Duração do Intervalo</vs-th>
        <vs-th>Ao final do período</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="i" v-for="(interval, i) in data" style="opacity: 1 !important">
          <vs-td>
            <vs-input type="time" :disabled="!configs.schedule.timesForCompany" @change="debouncedSave" v-model="interval.time" class="w-full" label="" />
          </vs-td>
          <vs-td>
              <!-- data-vv-as="Período" v-validate.initial="'required'" :name="'period_'+i" -->
              <v-select appendToBody class="vue_select_drop_size_160" @input="save" v-model="interval.afterBlock" :reduce="option => option.value"
                :disabled="!configs.schedule.timesForCompany"
                :clearable="true" :options="times" placeholder="Nenhum" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <!-- <span class="text-danger text-sm" v-show="errors.has('period_'+i)">Obrigatório</span> -->
          </vs-td>
          <vs-td>
            <vx-tooltip :text="i ? 'Remover Intervalo' : 'Adicionar Intervalo'" position="top" class="h-5 w-5 mt-0 ml-2">
              <feather-icon v-if="i === 0 && configs.schedule.timesForCompany" @click="addInterval" icon="PlusCircleIcon" svgClasses="w-6 h-6" class="cursor-pointer hover:text-primary" />
              <feather-icon v-else-if="configs.schedule.timesForCompany" @click="removeInterval(i)" icon="MinusCircleIcon" svgClasses="w-6 h-6" class="text-danger cursor-pointer hover:text-primary" />
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider></vs-divider>

    <!-- <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-0 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-0" @click="save" :disabled="!validateForm">Alterar</vs-button>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  components: {
    vSelect
  },
  props: {
    employee: { type: Object, required: true }
  },
  computed: {
    validateForm () {
      return !this.errors.any()
    },
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    },
    times () {
      const periods = [0, 1, 2] // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
      const h = this.$store.getters.scheduleClassTimes(this.employee)
      const res = h.filter(function (eachElem, index) {
        return periods.indexOf(index) !== -1
      }).flat()

      const select = []
      for (const k in res) {
        select.push({
          value: res[k],
          label: res[k]
        })
      }
      return select
    }
  },

  data () {
    return {
      configs: {
        schedule: {
          period: {
            matutino:   { start: '06:00', end: '11:50' },
            vespertino: { start: '13:00', end: '18:50' },
            noturno:    { start: '19:40', end: '23:50' }
          },
          intervals: [{ time: '', afterBlock: '' }],
          duration: 50,
          timesForCompany: true
        }
      }
    }
  },
  methods: {
    addInterval () {
      this.configs.schedule.intervals.push({
        time: '',
        afterBlock: ''
      })
    },
    removeInterval (idx) {
      this.configs.schedule.intervals.splice(idx, 1)
      this.save()
    },
    async save () {
      try {
        if (this.configs.schedule.period.matutino.start && this.configs.schedule.period.matutino.end) {
          const beginningTimeMatutino = moment(this.configs.schedule.period.matutino.start, 'HH:mm')
          const endTimeMatutino = moment(this.configs.schedule.period.matutino.end, 'HH:mm')
          if (beginningTimeMatutino.isSameOrAfter(endTimeMatutino)) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: 'O período inicial Matutino deve ser anterior ao período final Matutino.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }
        if (this.configs.schedule.period.vespertino.start && this.configs.schedule.period.vespertino.end) {
          const beginningTimeVespertino = moment(this.configs.schedule.period.vespertino.start, 'HH:mm')
          const endTimeVespertino = moment(this.configs.schedule.period.vespertino.end, 'HH:mm')
          if (beginningTimeVespertino.isSameOrAfter(endTimeVespertino)) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: 'O período inicial Vespertino deve ser anterior ao período final Vespertino.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }
        if (this.configs.schedule.period.noturno.start && this.configs.schedule.period.noturno.end) {
          const beginningTimeNoturno = moment(this.configs.schedule.period.noturno.start, 'HH:mm')
          const endTimeNoturno = moment(this.configs.schedule.period.noturno.end, 'HH:mm')
          if (beginningTimeNoturno.isSameOrAfter(endTimeNoturno)) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: 'O período inicial Noturno deve ser anterior ao período final Noturno.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }

        this.$vs.loading()
        this.employee.config = this.configs
        await this.$store.dispatch('employee/update', this.employee)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },

  mounted () {
    this.configs = JSON.parse(JSON.stringify(this.employee.config))
    if (!this.configs) {
      this.configs = {
        schedule: {
          period: {
            matutino:   { start: '06:00', end: '11:50' },
            vespertino: { start: '13:00', end: '18:50' },
            noturno:    { start: '19:40', end: '23:50' }
          },
          intervals: [{ time: '', afterBlock: '' }],
          duration: 50,
          timesForCompany: true
        }
      }
    } else if (!this.configs.schedule) {
      this.configs.schedule = {
        period: {
          matutino:   { start: '06:00', end: '11:50' },
          vespertino: { start: '13:00', end: '18:50' },
          noturno:    { start: '19:40', end: '23:50' }
        },
        intervals: [{ time: '', afterBlock: '' }],
        duration: 50,
        timesForCompany: true
      }
    } else if (!this.configs.schedule.period) {
      this.configs.schedule.period = {
        matutino:   { start: '06:00', end: '11:50' },
        vespertino: { start: '13:00', end: '18:50' },
        noturno:    { start: '19:40', end: '23:50' }
      }
      this.configs.schedule.intervals = [{ time: '', afterBlock: '' }]
      if (!this.configs.schedule.duration) {
        this.configs.schedule.duration = 50
      }
      if (this.configs.schedule.timesForCompany === undefined) this.configs.schedule = Object.assign({}, this.configs.schedule, { timesForCompany: false })
    } else if (this.configs.schedule.timesForCompany === undefined) this.configs.schedule = Object.assign({}, this.configs.schedule, { timesForCompany: false })

  },
  created () {
    this.debouncedSave = _.debounce(this.save, 1500)
  }
}
</script>

<style>
#table_ th .vs-table-text {
    cursor: default;
    text-align: center !important;
    display: unset;
}
#employee-edit-time-schedules-tab td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#employee-edit-time-schedules-tab td:hover {
  border-bottom: 1px solid #353434;
}
#employee-edit-time-schedules-tab td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#employee-edit-time-schedules-tab .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#employee-edit-time-schedules-tab .vs-table--thead {
  border: 1px solid transparent;
}
#employee-edit-time-schedules-tab .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#employee-edit-time-schedules-tab .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>

