<template>
  <div id="atendimento">
    <vs-collapse accordion type="margin">
      <vs-collapse-item :open="false">
        <div slot="header">Relatório de Alunos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_student_complete')" :key="`${i}_spa_report_student_complete`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_student_complete')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_student_complete" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_student_simple')" :key="`${i}_spa_report_student_simple`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_student_simple')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_student_simple" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_student_renach')" :key="`${i}_spa_report_student_renach`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_student_renach')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_student_renach" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Relatório Financeiro</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_boleto')" :key="`${i}_spa_report_financial_boleto`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_boleto')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_boleto" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_general_cashier')" :key="`${i}_spa_report_financial_general_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_general_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_general_cashier" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_bills_to_pay')" :key="`${i}_spa_report_financial_bills_to_pay`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_bills_to_pay')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_bills_to_pay" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_bills_to_receive')" :key="`${i}_spa_report_financial_bills_to_receive`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_bills_to_receive')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_bills_to_receive" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_contracts')" :key="`${i}_spa_report_financial_contracts`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_contracts')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_contracts" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_chargeback')" :key="`${i}_spa_report_financial_chargeback`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_chargeback')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_chargeback" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_my_cashier')" :key="`${i}_spa_report_financial_my_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_my_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_my_cashier" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_receipt')" :key="`${i}_spa_report_financial_receipt`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_receipt')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_receipt" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <!-- <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_financial_simplify')" :key="`${i}_spa_report_financial_simplify`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_financial_simplify')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_financial_simplify" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div> -->
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Relatório de CNH</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_cnh_emissao')" :key="`${i}_spa_report_cnh_emissao`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_cnh_emissao')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_cnh_emissao" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_cnh_entrega')" :key="`${i}_spa_report_cnh_entrega`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_cnh_entrega')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_cnh_entrega" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_cnh_vencimento')" :key="`${i}_spa_report_cnh_vencimento`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_cnh_vencimento')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_cnh_vencimento" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Relatório de Protocolos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_protocols_emitidos')" :key="`${i}_spa_report_protocols_emitidos`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_protocols_emitidos')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_protocols_emitidos" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Relatório de Agendas</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_practical_class')" :key="`${i}_spa_report_schedule_practical_class`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_practical_class')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_practical_class" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_theoretical_class')" :key="`${i}_spa_report_schedule_theoretical_class`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_theoretical_class')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_theoretical_class" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_commitments')" :key="`${i}_spa_report_schedule_commitments`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_commitments')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_commitments" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_medical_exam')" :key="`${i}_spa_report_schedule_medical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_medical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_medical_exam" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_practical_exam')" :key="`${i}_spa_report_schedule_practical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_practical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_practical_exam" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_psychotechnical_exam')" :key="`${i}_spa_report_schedule_psychotechnical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_psychotechnical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_psychotechnical_exam" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_schedule_theoretical_exam')" :key="`${i}_spa_report_schedule_theoretical_exam`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_schedule_theoretical_exam')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_schedule_theoretical_exam" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>

      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Relatório de Serviços</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_report_services')" :key="`${i}_spa_report_services`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_report_services')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-2">
            <vs-checkbox v-model="modules.spa_report_services" vs-value="generate" class="mr-4 mb-2">Gerar</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="mb-4" @click="save">Salvar Alterações</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissions: { type: Array, required: true },
    employee: { type: Object, required: true }
  },
  data () {
    return {
      modules: {
        spa_report_student_complete: [],
        spa_report_student_simple: [],
        spa_report_student_renach: [],
        spa_report_financial_boleto: [],
        spa_report_financial_general_cashier: [],
        spa_report_financial_bills_to_pay: [],
        spa_report_financial_bills_to_receive: [],
        spa_report_financial_contracts: [],
        spa_report_financial_chargeback: [],
        spa_report_financial_my_cashier: [],
        spa_report_financial_receipt: [],
        spa_report_financial_simplify: [],
        spa_report_cnh_emissao: [],
        spa_report_cnh_entrega: [],
        spa_report_cnh_vencimento: [],
        spa_report_protocols_emitidos: [],
        spa_report_schedule_practical_class: [],
        spa_report_schedule_theoretical_class: [],
        spa_report_schedule_commitments: [],
        spa_report_schedule_medical_exam: [],
        spa_report_schedule_practical_exam: [],
        spa_report_schedule_psychotechnical_exam: [],
        spa_report_schedule_theoretical_exam: [],
        spa_report_services: []
      }
    }
  },
  methods: {
    changeChecked (m) {
      if (this.modules[m].length) this.modules[m] = []
      else this.modules[m] = ['generate']
    },
    async save () {
      try {
        await this.$http.put(`${process.env.VUE_APP_API_URL}/user/permission/update/${this.employee.id}/`, this.modules)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    // console.log(this.employee.user.permissions_user)
    this.employee.user.permissions_user.map(a => {
      this.modules[a.module] = JSON.parse(a.pivot.permissions)
    })
  }
}
</script>

<style>
#atendimento .vs-collapse {
      padding: 0px !important;
}
</style>
