var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "atendimento" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c("vs-collapse-item", { attrs: { open: false } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("Alunos"),
            ]),
            _c(
              "div",
              [
                _vm._l(
                  _vm.permissions.filter((a) => a.module === "spa_students"),
                  function (permission, i) {
                    return _c(
                      "div",
                      { key: `${i}_spa_students`, staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked("spa_students")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_students,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_students", $$v)
                                  },
                                  expression: "modules.spa_students",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_students,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_students", $$v)
                                  },
                                  expression: "modules.spa_students",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter((a) => a.module === "spa_cnh"),
                  function (permission, i) {
                    return _c(
                      "div",
                      { key: `${i}_spa_cnh`, staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked("spa_cnh")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_cnh,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_cnh", $$v)
                                  },
                                  expression: "modules.spa_cnh",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_cnh,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_cnh", $$v)
                                  },
                                  expression: "modules.spa_cnh",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter((a) => a.module === "spa_tenant"),
                  function (permission, i) {
                    return _c(
                      "div",
                      { key: `${i}_spa_tenant`, staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked("spa_tenant")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_tenant,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_tenant", $$v)
                                  },
                                  expression: "modules.spa_tenant",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_tenant,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modules, "spa_tenant", $$v)
                                  },
                                  expression: "modules.spa_tenant",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_contract"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_spa_student_contract`,
                        staticClass: "vx-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_contract"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_contract,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_contract",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_contract",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_student_contract,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_contract",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_contract",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_carne"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      { key: `${i}_spa_student_carne`, staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_carne"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_carne,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_carne",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_carne",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_promissoria"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_spa_student_promissoria`,
                        staticClass: "vx-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_promissoria"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_promissoria,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_promissoria",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_promissoria",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_parcels"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_spa_student_parcels`,
                        staticClass: "vx-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_parcels"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_parcels,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_parcels",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_parcels",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_student_parcels,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_parcels",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_parcels",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_income"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      { key: `${i}_spa_student_income`, staticClass: "vx-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_income"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_income,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_income",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_income",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_student_income,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_income",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_income",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_expense"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_spa_student_expense`,
                        staticClass: "vx-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_expense"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_expense,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_expense",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_expense",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_student_expense,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_expense",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_expense",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                _vm._l(
                  _vm.permissions.filter(
                    (a) => a.module === "spa_student_batch_edit"
                  ),
                  function (permission, i) {
                    return _c(
                      "div",
                      {
                        key: `${i}_spa_student_batch_edit`,
                        staticClass: "vx-row",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeChecked(
                                      "spa_student_batch_edit"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(permission.description) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", {
                          staticClass: "vx-col md:w-1/6 w-full mt-2",
                        }),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "save" },
                                model: {
                                  value: _vm.modules.spa_student_batch_edit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_batch_edit",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_batch_edit",
                                },
                              },
                              [_vm._v("Salvar")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                          [
                            _c(
                              "vs-checkbox",
                              {
                                staticClass: "mr-4 mb-2",
                                attrs: { "vs-value": "delete" },
                                model: {
                                  value: _vm.modules.spa_student_batch_edit,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.modules,
                                      "spa_student_batch_edit",
                                      $$v
                                    )
                                  },
                                  expression: "modules.spa_student_batch_edit",
                                },
                              },
                              [_vm._v("Excluir")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Documentos"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_student_documents"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_student_documents`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_student_documents"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_student_documents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_student_documents",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_student_documents",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_student_documents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_student_documents",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_student_documents",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_student_documents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_student_documents",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_student_documents",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Veículos"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_vehicle"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_vehicle`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_vehicle")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_vehicle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_vehicle", $$v)
                                },
                                expression: "modules.spa_vehicle",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_vehicle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_vehicle", $$v)
                                },
                                expression: "modules.spa_vehicle",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_vehicle_maintenance"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_vehicle_maintenance`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_vehicle_maintenance"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_vehicle_maintenance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_maintenance",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_maintenance",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_vehicle_maintenance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_maintenance",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_maintenance",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_vehicle_maintenance,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_maintenance",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_maintenance",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_vehicle_supply"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_vehicle_supply`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_vehicle_supply")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_vehicle_supply,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_supply",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_supply",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_vehicle_supply,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_supply",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_supply",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_vehicle_supply,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_supply",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_supply",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_vehicle_service_order"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_vehicle_service_order`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_vehicle_service_order"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_vehicle_service_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_service_order",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_service_order",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_vehicle_service_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_service_order",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_service_order",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_vehicle_service_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_vehicle_service_order",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_vehicle_service_order",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }