var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "atendimento" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Funcionários"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_register_employee"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_register_employee`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_register_employee"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_register_employee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_employee",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_employee",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_register_employee,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_employee",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_employee",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Médico Psicólogo"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_register_doctor"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_register_doctor`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_register_doctor"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_register_doctor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_doctor",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_doctor",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_register_doctor,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_doctor",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_doctor",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("CACs"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_register_cac"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_register_cac`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_register_cac")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_register_cac,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_register_cac", $$v)
                                },
                                expression: "modules.spa_register_cac",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_register_cac,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_register_cac", $$v)
                                },
                                expression: "modules.spa_register_cac",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Fornecedores"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_register_provider"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_register_provider`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_register_provider"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_register_provider,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_provider",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_provider",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_register_provider,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_provider",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_provider",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Grupos"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_register_group"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_register_group`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_register_group")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_register_group,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_group",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_group",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_register_group,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_register_group",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_register_group",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }