<template>
  <div id="atendimento">
    <vs-collapse accordion type="margin">
      <vs-collapse-item :open="false">
        <div slot="header">Alunos</div>
        <div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_students')" :key="`${i}_spa_students`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_students')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_students" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_students" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_students" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_cnh')" :key="`${i}_spa_cnh`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_cnh')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_cnh" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_cnh" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_cnh" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_tenant')" :key="`${i}_spa_tenant`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_tenant')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_tenant" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_tenant" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_tenant" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_contract')" :key="`${i}_spa_student_contract`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_contract')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_contract" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_contract" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_contract" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_carne')" :key="`${i}_spa_student_carne`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_carne')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_carne" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_carne" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_carne" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_promissoria')" :key="`${i}_spa_student_promissoria`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_promissoria')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_promissoria" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_promissoria" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_promissoria" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_parcels')" :key="`${i}_spa_student_parcels`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_parcels')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_parcels" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_parcels" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_parcels" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_income')" :key="`${i}_spa_student_income`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_income')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_income" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_income" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_income" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_expense')" :key="`${i}_spa_student_expense`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_expense')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_expense" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_expense" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_expense" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
          <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_batch_edit')" :key="`${i}_spa_student_batch_edit`">
            <div class="vx-col md:w-1/2 w-full mt-2">
              <span @click="changeChecked('spa_student_batch_edit')"> {{ permission.description }} </span>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <!-- <vs-checkbox v-model="modules.spa_student_batch_edit" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_batch_edit" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
            </div>
            <div class="vx-col md:w-1/6 w-full mt-2">
              <vs-checkbox v-model="modules.spa_student_batch_edit" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
            </div>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Documentos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_student_documents')" :key="`${i}_spa_student_documents`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_student_documents')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_student_documents" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_student_documents" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_student_documents" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Veículos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_vehicle')" :key="`${i}_spa_vehicle`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_vehicle')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_vehicle" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_vehicle_maintenance')" :key="`${i}_spa_vehicle_maintenance`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_vehicle_maintenance')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_maintenance" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_maintenance" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_maintenance" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_vehicle_supply')" :key="`${i}_spa_vehicle_supply`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_vehicle_supply')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_supply" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_supply" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_supply" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_vehicle_service_order')" :key="`${i}_spa_vehicle_service_order`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_vehicle_service_order')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_service_order" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_service_order" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_vehicle_service_order" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="mb-4" @click="save">Salvar Alterações</vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    permissions: { type: Array, required: false },
    employee: { type: Object, required: true }
  },
  data () {
    return {
      modules: {
        spa_students: [],
        spa_cnh: [],
        spa_tenant: [],
        spa_student_contract: [],
        spa_student_carne: [],
        spa_student_promissoria: [],
        spa_student_parcels: [],
        spa_student_income: [],
        spa_student_expense: [],
        spa_student_batch_edit: [],

        spa_student_documents: [],

        spa_vehicle: [],
        spa_vehicle_maintenance: [],
        spa_vehicle_supply: [],
        spa_vehicle_service_order: []

      }
    }
  },
  methods: {
    changeChecked (m) {
      if (this.modules[m].length) this.modules[m] = []
      else this.modules[m] = ['read', 'save', 'delete']
    },
    async save () {
      try {
        await this.$http.put(`${process.env.VUE_APP_API_URL}/user/permission/update/${this.employee.id}/`, this.modules)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    // console.log(this.employee.user.permissions_user)
    this.employee.user.permissions_user.map(a => {
      this.modules[a.module] = JSON.parse(a.pivot.permissions)
    })
  }
}
</script>

<style>
#atendimento .vs-collapse {
  padding: 0px !important;
}
</style>
