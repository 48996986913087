var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.title
        ? _c("h3", { staticClass: "text-center text-primary" }, [
            _vm._v("\n    Permissões - " + _vm._s(_vm.title) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "vs-tabs",
        {
          attrs: {
            id: "access-permission-tab",
            position: "left",
            color: "primary",
          },
        },
        _vm._l(_vm.modules, function (item, idx) {
          return _c(
            "vs-tab",
            { key: idx, attrs: { label: item.name } },
            [
              _c(
                "vs-collapse",
                { attrs: { accordion: "", type: "margin" } },
                _vm._l(item.items, function (subItem, idz) {
                  return _c("vs-collapse-item", { key: idz }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(_vm._s(subItem.name)),
                    ]),
                    _c(
                      "div",
                      _vm._l(subItem.resources, function (resource, idw) {
                        return _c(
                          "div",
                          { key: idw },
                          [
                            _c("vs-divider", [_vm._v(_vm._s(resource.name))]),
                            _c(
                              "div",
                              { staticClass: "inline-flex" },
                              _vm._l(
                                resource.permissions,
                                function (rule, idy) {
                                  return _c(
                                    "div",
                                    {
                                      key: idy,
                                      on: {
                                        click: function ($event) {
                                          return _vm.checkGroupPermission(
                                            rule.value
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-checkbox",
                                        {
                                          attrs: {
                                            "vs-value": rule.value,
                                            disabled:
                                              _vm.groupsPermissions.includes(
                                                rule.value
                                              ),
                                          },
                                          model: {
                                            value: _vm.permissions,
                                            callback: function ($$v) {
                                              _vm.permissions = $$v
                                            },
                                            expression: "permissions",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(rule.name) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("vs-button", { on: { click: _vm.savePermissions } }, [
        _vm._v("Salvar"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }