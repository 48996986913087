var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "general_cashier_permissions" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Caixa Geral"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_general_cashier"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_general_cashier`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_general_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_general_cashier",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) =>
                    a.module === "spa_general_cashier_account_balance_total"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_account_balance_total`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_account_balance_total"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_general_cashier_account_balance_total,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_account_balance_total",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_account_balance_total",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) =>
                    a.module === "spa_general_cashier_transfer_between_account"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_transfer_between_account`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_transfer_between_account"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_general_cashier_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_transfer_between_account",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_general_cashier_expenses"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_expenses`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_expenses"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_general_cashier_expenses,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_expenses",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_expenses",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_general_cashier_income"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_income`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_income"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_general_cashier_income,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_income",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_income",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_general_cashier_batch_edit"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_batch_edit`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_batch_edit"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules.spa_general_cashier_batch_edit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_batch_edit",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_batch_edit",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _c("hr"),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_general_cashier_chargeback"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_general_cashier_chargeback`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_general_cashier_chargeback"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules.spa_general_cashier_chargeback,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_general_cashier_chargeback",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_general_cashier_chargeback",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Meu Caixa"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_my_cashier"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_my_cashier`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_my_cashier")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_my_cashier,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_my_cashier", $$v)
                                },
                                expression: "modules.spa_my_cashier",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_my_cashier_history_cashiers"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_my_cashier_history_cashiers`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_my_cashier_history_cashiers"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules.spa_my_cashier_history_cashiers,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_history_cashiers",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_history_cashiers",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_my_cashier_transfer_between_cashier"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_my_cashier_transfer_between_cashier`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_my_cashier_transfer_between_cashier"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_cashier",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_cashier",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_cashier",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_cashier",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_cashier",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_cashier",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_my_cashier_transfer_between_account"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_my_cashier_transfer_between_account`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_my_cashier_transfer_between_account"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_account",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_account",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_my_cashier_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_my_cashier_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_my_cashier_transfer_between_account",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Boleto"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_boleto"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_boleto`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_boleto")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_boleto,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_boleto", $$v)
                                },
                                expression: "modules.spa_boleto",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_boleto,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_boleto", $$v)
                                },
                                expression: "modules.spa_boleto",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Contas"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_account"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_account`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_account")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value: _vm.modules.spa_account,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_account", $$v)
                                },
                                expression: "modules.spa_account",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_account_transfer_between_account"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_account_transfer_between_account`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_account_transfer_between_account"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "read" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_account_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_account_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_account_transfer_between_account",
                              },
                            },
                            [_vm._v("Ver")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_account_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_account_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_account_transfer_between_account",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_account_transfer_between_account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_account_transfer_between_account",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_account_transfer_between_account",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("OS Interna"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_internal_service_order"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_internal_service_order`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_internal_service_order"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_internal_service_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_internal_service_order",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_internal_service_order",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "delete" },
                              model: {
                                value: _vm.modules.spa_internal_service_order,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_internal_service_order",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_internal_service_order",
                              },
                            },
                            [_vm._v("Excluir")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Recibos"),
              ]),
              _vm._l(
                _vm.permissions.filter((a) => a.module === "spa_recibo"),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_recibo`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_recibo")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_recibo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.modules, "spa_recibo", $$v)
                                },
                                expression: "modules.spa_recibo",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_single_receipt"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_single_receipt`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked("spa_single_receipt")
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "save" },
                              model: {
                                value: _vm.modules.spa_single_receipt,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_single_receipt",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_single_receipt",
                              },
                            },
                            [_vm._v("Salvar")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-2" }),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }