<template>
  <div id="general_cashier_permissions">
    <vs-collapse accordion type="margin">
      <vs-collapse-item :open="false">
        <div slot="header">Caixa Geral</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier')" :key="`${i}_spa_general_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
          <span @click="changeChecked('spa_general_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_account_balance_total')" :key="`${i}_spa_general_cashier_account_balance_total`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_account_balance_total')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_account_balance_total" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_account_balance_total" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_account_balance_total" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <!-- <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_transfer_between_cashier')" :key="`${i}_spa_general_cashier_transfer_between_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_transfer_between_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_cashier" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_cashier" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_cashier" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
        <hr> -->

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_transfer_between_account')" :key="`${i}_spa_general_cashier_transfer_between_account`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_transfer_between_account')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_account" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_account" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_transfer_between_account" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_expenses')" :key="`${i}_spa_general_cashier_expenses`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_expenses')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_expenses" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_expenses" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_expenses" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_income')" :key="`${i}_spa_general_cashier_income`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_income')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_income" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_income" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_income" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_batch_edit')" :key="`${i}_spa_general_cashier_batch_edit`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_batch_edit')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_batch_edit" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_batch_edit" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_batch_edit" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <hr>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_general_cashier_chargeback')" :key="`${i}_spa_general_cashier_chargeback`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_general_cashier_chargeback')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_chargeback" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_general_cashier_chargeback" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_general_cashier_chargeback" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
      </vs-collapse-item>

      <vs-collapse-item :open="false">
        <div slot="header">Meu Caixa</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_my_cashier')" :key="`${i}_spa_my_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_my_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_my_cashier" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_my_cashier" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_my_cashier_history_cashiers')" :key="`${i}_spa_my_cashier_history_cashiers`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_my_cashier_history_cashiers')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_history_cashiers" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_my_cashier_history_cashiers" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_my_cashier_history_cashiers" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_my_cashier_transfer_between_cashier')" :key="`${i}_spa_my_cashier_transfer_between_cashier`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_my_cashier_transfer_between_cashier')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_cashier" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_cashier" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_cashier" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_my_cashier_transfer_between_account')" :key="`${i}_spa_my_cashier_transfer_between_account`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_my_cashier_transfer_between_account')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_account" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_account" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_my_cashier_transfer_between_account" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Boleto</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_boleto')" :key="`${i}_spa_boleto`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_boleto')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_boleto" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_boleto" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_boleto" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Contas</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_account')" :key="`${i}_spa_account`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_account')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_account" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_account" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_account" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_account_transfer_between_account')" :key="`${i}_spa_account_transfer_between_account`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_account_transfer_between_account')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_account_transfer_between_account" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_account_transfer_between_account" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_account_transfer_between_account" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">OS Interna</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_internal_service_order')" :key="`${i}_spa_internal_service_order`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_internal_service_order')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_internal_service_order" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_internal_service_order" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_internal_service_order" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox>
          </div>
        </div>
      </vs-collapse-item>
      <vs-collapse-item :open="false">
        <div slot="header">Recibos</div>
        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_recibo')" :key="`${i}_spa_recibo`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_recibo')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_recibo" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_recibo" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_recibo" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>

        <div class="vx-row" v-for="(permission, i) in permissions.filter(a => a.module === 'spa_single_receipt')" :key="`${i}_spa_single_receipt`">
          <div class="vx-col md:w-1/2 w-full mt-2">
            <span @click="changeChecked('spa_single_receipt')"> {{ permission.description }} </span>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_recibo" vs-value="read" class="mr-4 mb-2">Ver</vs-checkbox> -->
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <vs-checkbox v-model="modules.spa_single_receipt" vs-value="save" class="mr-4 mb-2">Salvar</vs-checkbox>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <!-- <vs-checkbox v-model="modules.spa_recibo" vs-value="delete" class="mr-4 mb-2">Excluir</vs-checkbox> -->
          </div>
        </div>

      </vs-collapse-item>
    </vs-collapse>

    <vs-divider></vs-divider>

    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="mb-4" @click="save">Salvar Alterações</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    permissions: { type: Array, required: true },
    employee: { type: Object, required: true }
  },
  data () {
    return {
      modules: {
        spa_general_cashier: [],
        spa_general_cashier_account_balance_total: [],
        spa_general_cashier_transfer_between_cashier: [],
        spa_general_cashier_transfer_between_account: [],
        spa_general_cashier_expenses: [],
        spa_general_cashier_income: [],
        spa_general_cashier_batch_edit: [],
        spa_general_cashier_chargeback: [],
        spa_my_cashier: [],
        spa_my_cashier_history_cashiers: [],
        spa_my_cashier_transfer_between_cashier: [],
        spa_my_cashier_transfer_between_account: [],
        spa_boleto: [],
        spa_account: [],
        spa_account_transfer_between_account: [],
        spa_internal_service_order: [],
        spa_recibo: []
      }
    }
  },
  methods: {
    changeChecked (m) {
      if (this.modules[m].length) this.modules[m] = []
      else this.modules[m] = ['read', 'save', 'delete']
    },
    async save () {
      try {
        await this.$http.put(`${process.env.VUE_APP_API_URL}/user/permission/update/${this.employee.id}/`, this.modules)
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    }
  },
  mounted () {
    // console.log(this.employee.user.permissions_user)
    this.employee.user.permissions_user.map(a => {
      this.modules[a.module] = JSON.parse(a.pivot.permissions)
    })
  }
}
</script>

<style>
#atendimento .vs-collapse {
  padding: 0px !important;
}
</style>
