var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-alert",
        {
          attrs: {
            color: "danger",
            title: "Funcionário não encontrado!",
            active: _vm.employee_not_found,
          },
          on: {
            "update:active": function ($event) {
              _vm.employee_not_found = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "O funcionário com código: " +
                _vm._s(_vm.$route.params.employeeId) +
                " não foi encontrado. "
            ),
          ]),
          _c(
            "span",
            [
              _c("span", [_vm._v("Verifique ")]),
              _c(
                "router-link",
                {
                  staticClass: "text-inherit underline",
                  attrs: { to: { name: "registrations" } },
                },
                [_vm._v("a lista de funcionários.")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.ready && !_vm.employee_not_found
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  {
                    staticClass: "tab-action-btn-fill-conatiner",
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Dados Gerais",
                          "icon-pack": "feather",
                          icon: "icon-user",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("general-data", {
                              ref: "general_data",
                              staticClass: "mt-4",
                              attrs: { employee: _vm.employee },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Permissões de Acesso",
                          "icon-pack": "feather",
                          icon: "icon-unlock",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _vm._v("~\n            "),
                            _c("access-permissions", { staticClass: "mt-4" }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Novas Permissões",
                          "icon-pack": "feather",
                          icon: "icon-unlock",
                        },
                      },
                      [
                        _c("div", { staticClass: "tab-text" }, [
                          _c("div", { staticClass: "vx-row" }, [
                            _c(
                              "div",
                              { staticClass: "vx-col w-4/5" },
                              [
                                _c("vs-divider", [
                                  _vm._v("Permissões Individuais"),
                                ]),
                                _c("list-permission", {
                                  attrs: {
                                    groups: _vm.userGroups,
                                    title: _vm.employee.name,
                                    "user-id": _vm.userId,
                                  },
                                  model: {
                                    value: _vm.permissions,
                                    callback: function ($$v) {
                                      _vm.permissions = $$v
                                    },
                                    expression: "permissions",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "vx-col w-1/5" },
                              [
                                _c("vs-divider", [_vm._v("Grupos")]),
                                _c("select-group", {
                                  model: {
                                    value: _vm.userGroups,
                                    callback: function ($$v) {
                                      _vm.userGroups = $$v
                                    },
                                    expression: "userGroups",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Horários de Trabalho",
                          "icon-pack": "feather",
                          icon: "icon-file-text",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [_c("blocking-schedules", { staticClass: "mt-4" })],
                          1
                        ),
                      ]
                    ),
                    _vm.companyConfigurations.schedule &&
                    _vm.companyConfigurations.schedule.timesForCompany === false
                      ? _c(
                          "vs-tab",
                          {
                            attrs: {
                              label: "Horários de Agenda",
                              "icon-pack": "feather",
                              icon: "icon-calendar",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tab-text" },
                              [
                                _c("time-schedules", {
                                  staticClass: "mt-4",
                                  attrs: { employee: _vm.employee },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Comissões",
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("commissions", {
                              staticClass: "mt-4",
                              attrs: { employee: _vm.employee },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }