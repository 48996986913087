var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "atendimento" } },
    [
      _c(
        "vs-collapse",
        { attrs: { accordion: "", type: "margin" } },
        [
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório de Alunos"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_student_complete"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_student_complete`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_student_complete"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_student_complete,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_student_complete",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_student_complete",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_student_simple"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_student_simple`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_student_simple"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_student_simple,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_student_simple",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_student_simple",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_student_renach"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_student_renach`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_student_renach"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_student_renach,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_student_renach",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_student_renach",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório Financeiro"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_boleto"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_boleto`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_boleto"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_financial_boleto,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_boleto",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_boleto",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_general_cashier"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_general_cashier`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_general_cashier"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_financial_general_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_general_cashier",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_general_cashier",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_bills_to_pay"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_bills_to_pay`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_bills_to_pay"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_financial_bills_to_pay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_bills_to_pay",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_bills_to_pay",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_bills_to_receive"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_bills_to_receive`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_bills_to_receive"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_financial_bills_to_receive,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_bills_to_receive",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_bills_to_receive",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_contracts"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_contracts`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_contracts"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_financial_contracts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_contracts",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_contracts",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_chargeback"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_chargeback`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_chargeback"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_financial_chargeback,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_chargeback",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_chargeback",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_my_cashier"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_my_cashier`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_my_cashier"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_financial_my_cashier,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_my_cashier",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_my_cashier",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_financial_receipt"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_financial_receipt`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_financial_receipt"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_financial_receipt,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_financial_receipt",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_financial_receipt",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório de CNH"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_cnh_emissao"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_cnh_emissao`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_cnh_emissao"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_cnh_emissao,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_cnh_emissao",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_cnh_emissao",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_cnh_entrega"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_cnh_entrega`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_cnh_entrega"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_cnh_entrega,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_cnh_entrega",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_cnh_entrega",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_cnh_vencimento"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_cnh_vencimento`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_cnh_vencimento"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_cnh_vencimento,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_cnh_vencimento",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_cnh_vencimento",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório de Protocolos"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_protocols_emitidos"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_protocols_emitidos`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_protocols_emitidos"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_protocols_emitidos,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_protocols_emitidos",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_protocols_emitidos",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório de Agendas"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_practical_class"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_practical_class`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_practical_class"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_schedule_practical_class,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_practical_class",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_practical_class",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_theoretical_class"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_theoretical_class`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_theoretical_class"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_schedule_theoretical_class,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_theoretical_class",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_theoretical_class",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_commitments"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_commitments`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_commitments"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_schedule_commitments,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_commitments",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_commitments",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_medical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_medical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_medical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules.spa_report_schedule_medical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_medical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_medical_exam",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_practical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_practical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_practical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_schedule_practical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_practical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_practical_exam",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_psychotechnical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_psychotechnical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_psychotechnical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_schedule_psychotechnical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_psychotechnical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_psychotechnical_exam",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_schedule_theoretical_exam"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    {
                      key: `${i}_spa_report_schedule_theoretical_exam`,
                      staticClass: "vx-row",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_schedule_theoretical_exam"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value:
                                  _vm.modules
                                    .spa_report_schedule_theoretical_exam,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_schedule_theoretical_exam",
                                    $$v
                                  )
                                },
                                expression:
                                  "modules.spa_report_schedule_theoretical_exam",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _c(
            "vs-collapse-item",
            { attrs: { open: false } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Relatório de Serviços"),
              ]),
              _vm._l(
                _vm.permissions.filter(
                  (a) => a.module === "spa_report_services"
                ),
                function (permission, i) {
                  return _c(
                    "div",
                    { key: `${i}_spa_report_services`, staticClass: "vx-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeChecked(
                                    "spa_report_services"
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(permission.description) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-2" },
                        [
                          _c(
                            "vs-checkbox",
                            {
                              staticClass: "mr-4 mb-2",
                              attrs: { "vs-value": "generate" },
                              model: {
                                value: _vm.modules.spa_report_services,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.modules,
                                    "spa_report_services",
                                    $$v
                                  )
                                },
                                expression: "modules.spa_report_services",
                              },
                            },
                            [_vm._v("Gerar")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                { staticClass: "mb-4", on: { click: _vm.save } },
                [_vm._v("Salvar Alterações")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }