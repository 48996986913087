var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-edit-time-schedules-tab" } },
    [
      _c("h5", { staticClass: "p-3" }, [_vm._v("Horários das Agendas")]),
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { id: "table_", noDataText: "", data: [], hoverFlat: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Matutino")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: _vm.configs.schedule.period.matutino.start,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.matutino,
                                  "start",
                                  $$v
                                )
                              },
                              expression:
                                "configs.schedule.period.matutino.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: _vm.configs.schedule.period.matutino.end,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.matutino,
                                  "end",
                                  $$v
                                )
                              },
                              expression:
                                "configs.schedule.period.matutino.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Vespertino")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value:
                                _vm.configs.schedule.period.vespertino.start,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.vespertino,
                                  "start",
                                  $$v
                                )
                              },
                              expression:
                                "configs.schedule.period.vespertino.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: _vm.configs.schedule.period.vespertino.end,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.vespertino,
                                  "end",
                                  $$v
                                )
                              },
                              expression:
                                "configs.schedule.period.vespertino.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v("Noturno")]
                        ),
                      ]),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: _vm.configs.schedule.period.noturno.start,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.noturno,
                                  "start",
                                  $$v
                                )
                              },
                              expression:
                                "configs.schedule.period.noturno.start",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-td",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: _vm.configs.schedule.period.noturno.end,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.configs.schedule.period.noturno,
                                  "end",
                                  $$v
                                )
                              },
                              expression: "configs.schedule.period.noturno.end",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Período")]),
              _c("vs-th", [_vm._v("Início")]),
              _c("vs-th", [_vm._v("Fim")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("h5", { staticClass: "p-3" }, [_vm._v("Intervalos")]),
      _c(
        "vs-table",
        {
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: {
            noDataText: "",
            data: _vm.configs.schedule.intervals,
            hoverFlat: "",
            stripe: "",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (interval, i) {
                  return _c(
                    "vs-tr",
                    { key: i, staticStyle: { opacity: "1 !important" } },
                    [
                      _c(
                        "vs-td",
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              type: "time",
                              disabled: !_vm.configs.schedule.timesForCompany,
                              label: "",
                            },
                            on: { change: _vm.debouncedSave },
                            model: {
                              value: interval.time,
                              callback: function ($$v) {
                                _vm.$set(interval, "time", $$v)
                              },
                              expression: "interval.time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-td",
                        [
                          _c("v-select", {
                            staticClass: "vue_select_drop_size_160",
                            attrs: {
                              appendToBody: "",
                              reduce: (option) => option.value,
                              disabled: !_vm.configs.schedule.timesForCompany,
                              clearable: true,
                              options: _vm.times,
                              placeholder: "Nenhum",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            on: { input: _vm.save },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "no-options",
                                  fn: function ({}) {
                                    return [
                                      _vm._v(
                                        "\n                Desculpe, nenhum resultado encontrado.\n              "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: interval.afterBlock,
                              callback: function ($$v) {
                                _vm.$set(interval, "afterBlock", $$v)
                              },
                              expression: "interval.afterBlock",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vx-tooltip",
                            {
                              staticClass: "h-5 w-5 mt-0 ml-2",
                              attrs: {
                                text: i
                                  ? "Remover Intervalo"
                                  : "Adicionar Intervalo",
                                position: "top",
                              },
                            },
                            [
                              i === 0 && _vm.configs.schedule.timesForCompany
                                ? _c("feather-icon", {
                                    staticClass:
                                      "cursor-pointer hover:text-primary",
                                    attrs: {
                                      icon: "PlusCircleIcon",
                                      svgClasses: "w-6 h-6",
                                    },
                                    on: { click: _vm.addInterval },
                                  })
                                : _vm.configs.schedule.timesForCompany
                                ? _c("feather-icon", {
                                    staticClass:
                                      "text-danger cursor-pointer hover:text-primary",
                                    attrs: {
                                      icon: "MinusCircleIcon",
                                      svgClasses: "w-6 h-6",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeInterval(i)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("Duração do Intervalo")]),
              _c("vs-th", [_vm._v("Ao final do período")]),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }