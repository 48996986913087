<template>
  <div id="employee-edit-blocking-schedules-tab">
    <h5 class="mb-1">Horário de Expediente</h5>
    <vs-table id="table_" noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th style="font-size: 11px; text-align:center !important"> </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_seg" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> SEG.</vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_ter" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> TER.</vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_qua" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> QUA.</vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_qui" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> QUI.</vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_sex" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> SEX.</vs-checkbox>
        </vs-th>
        <vs-th style="font-size: 11px; text-align:center !important">
          <vs-checkbox v-model="employee.office_hour.work_sab" :vs-value="true" class="vs-checkbox-small ml-0 mr-1"> SÁB.</vs-checkbox>
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:center"><span class="cursor-default font-semibold"> INÍCIO </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_seg" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_seg }" name="start_time_seg" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_seg')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_ter" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_ter }" name="start_time_ter" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_ter')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_qua" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_qua }" name="start_time_qua" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_qua')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_qui" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_qui }" name="start_time_qui" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_qui')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_sex" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_sex }" name="start_time_sex" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_sex')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.start_time_sab" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_sab }" name="start_time_sab" />
            <span class="text-danger text-sm" v-show="errors.has('start_time_sab')">Obrigatório</span>
          </span></vs-td>
        </vs-tr>

        <vs-tr style="font-size: 12px; opacity: 1 !important">
          <vs-td style="text-align:center"><span class="cursor-default font-semibold"> FIM </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_seg" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_seg }" name="final_time_seg" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_seg')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_ter" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_ter }" name="final_time_ter" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_ter')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_qua" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_qua }" name="final_time_qua" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_qua')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_qui" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_qui }" name="final_time_qui" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_qui')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_sex" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_sex }" name="final_time_sex" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_sex')">Obrigatório</span>
          </span></vs-td>
          <vs-td style="text-align:center"><span class="cursor-default font-semibold">
            <vs-input type="time" v-model="employee.office_hour.final_time_sab" class="w-full" label="" v-validate.initial="{ required: !!employee.office_hour.work_sab }" name="final_time_sab" />
            <span class="text-danger text-sm" v-show="errors.has('final_time_sab')">Obrigatório</span>
          </span></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-divider></vs-divider>

    <vs-table noDataText="" :data="[]" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
      <template slot="thead">
        <vs-th style="font-size: 11px; width: 120px;">
          <vs-checkbox v-model="employee.office_hour.lunch_time" :vs-value="true" class="ml-0 mr-1"> ALMOÇO</vs-checkbox>
        </vs-th>

        <vs-th style="font-size: 11px; width: 150px;">
          <vs-input type="time" v-model="employee.office_hour.lunch_time_ini" class="w-full" label="Início" v-validate.initial="{ required: !!employee.office_hour.lunch_time }" name="lunch_time_ini" />
          <span class="text-danger text-sm" v-show="errors.has('lunch_time_ini')">Obrigatório</span>
        </vs-th>
        <vs-th style="font-size: 11px; width: 150px;">
          <vs-input type="time" v-model="employee.office_hour.lunch_time_fin" class="w-full" label="Fim" v-validate.initial="{ required: !!employee.office_hour.lunch_time }" name="lunch_time_fin" />
          <span class="text-danger text-sm" v-show="errors.has('lunch_time_fin')">Obrigatório</span>
        </vs-th>
        <vs-th>
          <vs-checkbox v-model="employee.office_hour.deny_system_access_out_office_hour" :vs-value="true" class="ml-auto mr-1"> <h6>Proibir acesso fora do horário de expediente</h6></vs-checkbox>
        </vs-th>
      </template>
    </vs-table>

    <h5 class="mb-1">Adicionar Intervalos</h5>
      <div class="vx-row" v-for="(brk, i) in employee.office_hour.breaks" :key="i">
        <div class="vx-col md:w-1/4 w-full mt-2">
          <label class="vs-input--label">Dia da Semana</label>
          <v-select appendToBody class="vue_select_drop_size_200" v-model="brk.weekday" :clearable="true"
            :reduce="option => option.value" placeholder="Selecione"
            :options="[ { value: 1, label: 'Segunda Feira' },
                        { value: 2, label: 'Terça Feira'   },
                        { value: 3, label: 'Quarta Feira'  },
                        { value: 4, label: 'Quinta Feira'  },
                        { value: 5, label: 'Sexta Feira'   },
                        { value: 6, label: 'Sábado'        }]" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Desculpe, nenhum resultado encontrado.
            </template>
          </v-select>
        </div>

        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input type="time" v-model="brk.start_time" class="w-full" label="Início" />
          <span class="text-danger text-sm" v-show="errors.has('start_time')">{{ errors.first('start_time') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-2">
          <vs-input type="time" v-model="brk.end_time" class="w-full" label="Fim" />
          <span class="text-danger text-sm" v-show="errors.has('end_time')">{{ errors.first('end_time') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
            <vx-tooltip :text="i ? 'Remover Intervalo' : 'Adicionar Intervalo'" position="top" class="h-8 w-8 mt-8">
              <feather-icon v-if="i === 0" @click="addField" icon="PlusCircleIcon" svgClasses="w-8 h-8" class="cursor-pointer hover:text-primary flex" />
              <feather-icon v-else @click="removeField(i)" icon="MinusCircleIcon" svgClasses="w-8 h-8" class="text-danger cursor-pointer hover:text-primary" />
            </vx-tooltip>
        </div>
      </div>

    <div class="vx-row">
      <div class="vx-col md:w-2/3 w-full">
        <vs-alert class="mt-2 mb-4" color="primary" title="Orientações:" :active="true" icon-pack="feather">
          <small>Defina ao lado se você deseja impedir os agendamentos fora dos horários de trabalho deste funcionário ou somente ser avisado quando isto ocorrer.</small>
        </vs-alert>
      </div>
      <div class="vx-col md:w-1/3 w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-switch class="ml-auto mt-4" v-model="employee.office_hour.block_time">
            <span slot="on">Impedir Agendamentos</span> <span slot="off">Somente Avisar</span>
          </vs-switch>
          <vs-button class="ml-4 mt-2" @click="save" :disabled="!validateForm">Alterar</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateForm () {
      return !this.errors.any()
    }
  },
  data () {
    return {
      employee: {}
    }
  },
  methods: {
    async save () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_register_employee')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      try {
        this.$vs.loading()
        await this.$store.dispatch('employee/updateBlockingSchedules', this.employee)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    addField () {
      this.employee.office_hour.breaks.push({
        weekday: null,
        start_time: null,
        end_time: null
      })
    },
    removeField (index) {
      this.employee.office_hour.breaks.splice(index, 1)
    }
  },

  created () {
    this.employee = JSON.parse(JSON.stringify(this.$store.getters['employee/get']))
  }
}
</script>

<style>
#table_ th .vs-table-text {
    cursor: default;
    text-align: center !important;
    display: unset;
}
#employee-edit-blocking-schedules-tab td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#employee-edit-blocking-schedules-tab td:hover {
  border-bottom: 1px solid #353434;
}
#employee-edit-blocking-schedules-tab td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#employee-edit-blocking-schedules-tab .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#employee-edit-blocking-schedules-tab .vs-table--thead {
  border: 1px solid transparent;
}
#employee-edit-blocking-schedules-tab .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#employee-edit-blocking-schedules-tab .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
