var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vx-col" },
    [
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|min:3",
            expression: "'required|min:3'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "data-vv-delay": "500",
          label: "Nome",
          name: "name",
          "data-vv-as": "Nome",
          "danger-text": _vm.errors.first("name"),
          danger: _vm.errors.has("name"),
          success: !_vm.errors.has("name"),
        },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex justify-end" },
        [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }