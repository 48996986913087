<template>
  <div class="vx-row">
    <vs-popup
      class="popup40"
      :fullscreen="false"
      title="Cadastrar / Editar Grupo"
      :active.sync="popupFormGroup"
    >
      <form-group v-model="groupEditing" @saved="popupFormGroup = false"/>
    </vs-popup>
    <div class="vx-col w-2/5">
      <div class="inline-flex">
        <vs-button @click="openFormToCreateGroup">Cadastrar</vs-button>
      </div>
      <vs-divider />
      <vs-table :data="groups" noDataText="Sem dados">
        <template slot="header">
          <h3 class="text-primary">Lista de grupos</h3>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            v-for="(group, idx) in data"
            :key="idx"
            :state="group.name === selected.name ? 'success' : ''"
          >
            <vs-td>
              <div class="inline-flex">
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-primary"
                  svgClasses="h-4 hover:text-primary cursor-pointer"
                  @click="editGroupPermission(group)"
                />
                <feather-icon
                  icon="EditIcon"
                  class="text-primary"
                  svgClasses="h-4 hover:text-primary cursor-pointer"
                  @click="editGroup(group)"
                />
                <feather-icon
                  icon="Trash2Icon"
                  class="text-primary"
                  svgClasses="h-4 hover:text-primary cursor-pointer"
                  @click="removeGroup(group)"
                />
              </div>
              {{ group.name }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="vx-col w-3/5">
      <list-permission v-model="selected.permissions" :title="selected.name" :group-id="selected.id" />
    </div>
  </div>
</template>

<script>
import ListPermission from '@/components/general/permission/ListPermission.vue'
import FormGroup from './FormGroup.vue'

export default {
  components: {
    ListPermission,
    FormGroup
  },
  data () {
    return {
      selected: {},
      groupEditing: null,
      popupFormGroup: false
    }
  },
  computed: {
    groups () {
      return this.$store.state.group.groups
    }
  },
  methods: {
    editGroupPermission (group) {
      this.selected = {...group}
    },
    editGroup (group) {
      this.groupEditing = {...group}
      this.popupFormGroup = true
    },
    removeGroup (group) {
      this.$vs.loading()
      this.$store.dispatch('group/remove', group).then(this.mixinThen()).catch(this.mixinCatch).finally(this.mixinFinally)
    },
    openFormToCreateGroup () {
      this.groupEditing = null
      this.popupFormGroup = true
    }
  },
  created () {
    if (!this.groups.length) {
      this.$store.dispatch('group/fetchAll')
    }
  }
}
</script>

<style>
.popup40 .vs-popup {
  width: 40% !important;
}
</style>
