<template>
  <div>
    <vs-alert color="danger" title="Funcionário não encontrado!" :active.sync="employee_not_found">
      <span>O funcionário com código: {{ $route.params.employeeId }} não foi encontrado. </span>
      <span>
        <span>Verifique </span>
        <router-link :to="{ name:'registrations' }" class="text-inherit underline">a lista de funcionários.</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="ready && !employee_not_found">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Dados Gerais" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <general-data class="mt-4" :employee="employee" ref="general_data" />
            </div>
          </vs-tab>
           <vs-tab label="Permissões de Acesso" icon-pack="feather" icon="icon-unlock">
            <div class="tab-text">~
              <access-permissions class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Novas Permissões" icon-pack="feather" icon="icon-unlock">
            <div class="tab-text">
              <div class="vx-row">
                 <div class="vx-col w-4/5">
                  <vs-divider>Permissões Individuais</vs-divider>
                  <list-permission v-model="permissions" :groups="userGroups" :title="employee.name" :user-id="userId" />
                </div>
                <div class="vx-col w-1/5">
                 <vs-divider>Grupos</vs-divider>
                   <select-group v-model="userGroups"/>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab label="Horários de Trabalho" icon-pack="feather" icon="icon-file-text">
            <div class="tab-text">
              <blocking-schedules class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab v-if="companyConfigurations.schedule && companyConfigurations.schedule.timesForCompany === false" label="Horários de Agenda" icon-pack="feather" icon="icon-calendar">
            <div class="tab-text">
              <time-schedules :employee="employee" class="mt-4" />
            </div>
          </vs-tab>
          <vs-tab label="Comissões" icon-pack="feather" icon="icon-dollar-sign">
            <div class="tab-text">
              <commissions :employee="employee" class="mt-4" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import moduleEmployee     from '@/store/employee/moduleEmployee.js'
import GeneralData        from './tabs/GeneralData.vue'
import AccessPermissions  from './tabs/access-permissions/AccessPermissions.vue'
import BlockingSchedules  from './tabs/BlockingSchedules.vue'
import TimeSchedules      from './tabs/TimeSchedules.vue'
import Commissions        from './tabs/Commissions.vue'
import ListPermission from '@/components/general/permission/ListPermission.vue'
import ListGroup from '@/components/register/group/ListGroup.vue'
import SelectGroup from '@/components/register/group/SelectGroup.vue'
import moduleRoles        from '@/store/roles/moduleRoles.js'

export default {
  components: {
    GeneralData,
    ListPermission,
    AccessPermissions,
    BlockingSchedules,
    TimeSchedules,
    SelectGroup,
    ListGroup,
    Commissions
  },
  computed: {
    companyConfigurations () {
      return this.$store.getters.companyConfigurations
    }
  },

  data () {
    return {
      employee: {},
      ready: false,
      employee_not_found: false,
      userGroups: null, // grupos em que o usuario está incluído
      activeTab: 0,
      permissions: [], // lista de permissões do usuario sendo editado
      userId: null // id do usuário sendo editado
    }
  },
  created () {
    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$vs.loading()
    this.$store.dispatch('employee/fetchEmployee', this.$route.params.employeeId)
      .then((response) => {
        if (!response.data.id) {
          this.employee_not_found = true
        }
        this.ready = true
        this.activeTab = 0
        this.$vs.loading.close()
        this.employee = JSON.parse(JSON.stringify(this.$store.getters['employee/get']))
        if (this.employee.user) {
          this.employee.groups = this.employee.user.groups
          this.permissions = this.employee.user.group_permissions
          this.userId = this.employee.user.id
        } else {
          this.employee.groups = []
          this.userId = null
          this.permissions = []
        }
        this.userGroups = this.employee.groups
      })
      .catch(err => {
        console.error(err)
        this.$vs.loading.close()
      })

    if (!moduleRoles.isRegistered) {
      this.$store.registerModule('roles', moduleRoles)
      moduleRoles.isRegistered = true
    }
    this.$store.dispatch('roles/fetchAll')

  }
}
</script>

<style>

</style>
