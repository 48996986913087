var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-edit-blocking-schedules-tab" } },
    [
      _c("h5", { staticClass: "mb-1" }, [_vm._v("Horário de Expediente")]),
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { id: "table_", noDataText: "", data: [], hoverFlat: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return [
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v(" INÍCIO ")]
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_seg,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_seg }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_seg",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_seg,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_seg",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_seg",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_seg"),
                                    expression: "errors.has('start_time_seg')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_ter,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_ter }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_ter",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_ter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_ter",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_ter",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_ter"),
                                    expression: "errors.has('start_time_ter')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_qua,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_qua }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_qua",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_qua,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_qua",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_qua",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_qua"),
                                    expression: "errors.has('start_time_qua')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_qui,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_qui }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_qui",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_qui,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_qui",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_qui",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_qui"),
                                    expression: "errors.has('start_time_qui')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_sex,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_sex }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_sex",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_sex,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_sex",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_sex",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_sex"),
                                    expression: "errors.has('start_time_sex')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_sab,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_sab }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "start_time_sab",
                              },
                              model: {
                                value: _vm.employee.office_hour.start_time_sab,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "start_time_sab",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.start_time_sab",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("start_time_sab"),
                                    expression: "errors.has('start_time_sab')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "vs-tr",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        opacity: "1 !important",
                      },
                    },
                    [
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [_vm._v(" FIM ")]
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_seg,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_seg }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_seg",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_seg,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_seg",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_seg",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_seg"),
                                    expression: "errors.has('final_time_seg')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_ter,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_ter }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_ter",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_ter,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_ter",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_ter",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_ter"),
                                    expression: "errors.has('final_time_ter')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_qua,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_qua }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_qua",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_qua,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_qua",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_qua",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_qua"),
                                    expression: "errors.has('final_time_qua')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_qui,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_qui }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_qui",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_qui,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_qui",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_qui",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_qui"),
                                    expression: "errors.has('final_time_qui')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_sex,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_sex }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_sex",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_sex,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_sex",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_sex",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_sex"),
                                    expression: "errors.has('final_time_sex')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("vs-td", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          { staticClass: "cursor-default font-semibold" },
                          [
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.initial",
                                  value: {
                                    required:
                                      !!_vm.employee.office_hour.work_sab,
                                  },
                                  expression:
                                    "{ required: !!employee.office_hour.work_sab }",
                                  modifiers: { initial: true },
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                type: "time",
                                label: "",
                                name: "final_time_sab",
                              },
                              model: {
                                value: _vm.employee.office_hour.final_time_sab,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.employee.office_hour,
                                    "final_time_sab",
                                    $$v
                                  )
                                },
                                expression:
                                  "employee.office_hour.final_time_sab",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("final_time_sab"),
                                    expression: "errors.has('final_time_sab')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Obrigatório")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", {
                staticStyle: {
                  "font-size": "11px",
                  "text-align": "center !important",
                },
              }),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_seg,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_seg", $$v)
                        },
                        expression: "employee.office_hour.work_seg",
                      },
                    },
                    [_vm._v(" SEG.")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_ter,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_ter", $$v)
                        },
                        expression: "employee.office_hour.work_ter",
                      },
                    },
                    [_vm._v(" TER.")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_qua,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_qua", $$v)
                        },
                        expression: "employee.office_hour.work_qua",
                      },
                    },
                    [_vm._v(" QUA.")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_qui,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_qui", $$v)
                        },
                        expression: "employee.office_hour.work_qui",
                      },
                    },
                    [_vm._v(" QUI.")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_sex,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_sex", $$v)
                        },
                        expression: "employee.office_hour.work_sex",
                      },
                    },
                    [_vm._v(" SEX.")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                {
                  staticStyle: {
                    "font-size": "11px",
                    "text-align": "center !important",
                  },
                },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "vs-checkbox-small ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.work_sab,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "work_sab", $$v)
                        },
                        expression: "employee.office_hour.work_sab",
                      },
                    },
                    [_vm._v(" SÁB.")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("vs-divider"),
      _c(
        "vs-table",
        {
          staticClass: "bordered",
          staticStyle: { overflow: "-webkit-paged-y" },
          attrs: { noDataText: "", data: [], hoverFlat: "" },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "120px" } },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "ml-0 mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value: _vm.employee.office_hour.lunch_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.employee.office_hour, "lunch_time", $$v)
                        },
                        expression: "employee.office_hour.lunch_time",
                      },
                    },
                    [_vm._v(" ALMOÇO")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "150px" } },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: {
                          required: !!_vm.employee.office_hour.lunch_time,
                        },
                        expression:
                          "{ required: !!employee.office_hour.lunch_time }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      type: "time",
                      label: "Início",
                      name: "lunch_time_ini",
                    },
                    model: {
                      value: _vm.employee.office_hour.lunch_time_ini,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.employee.office_hour,
                          "lunch_time_ini",
                          $$v
                        )
                      },
                      expression: "employee.office_hour.lunch_time_ini",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("lunch_time_ini"),
                          expression: "errors.has('lunch_time_ini')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                { staticStyle: { "font-size": "11px", width: "150px" } },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: {
                          required: !!_vm.employee.office_hour.lunch_time,
                        },
                        expression:
                          "{ required: !!employee.office_hour.lunch_time }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "w-full",
                    attrs: {
                      type: "time",
                      label: "Fim",
                      name: "lunch_time_fin",
                    },
                    model: {
                      value: _vm.employee.office_hour.lunch_time_fin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.employee.office_hour,
                          "lunch_time_fin",
                          $$v
                        )
                      },
                      expression: "employee.office_hour.lunch_time_fin",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("lunch_time_fin"),
                          expression: "errors.has('lunch_time_fin')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "vs-th",
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "ml-auto mr-1",
                      attrs: { "vs-value": true },
                      model: {
                        value:
                          _vm.employee.office_hour
                            .deny_system_access_out_office_hour,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.employee.office_hour,
                            "deny_system_access_out_office_hour",
                            $$v
                          )
                        },
                        expression:
                          "employee.office_hour.deny_system_access_out_office_hour",
                      },
                    },
                    [
                      _c("h6", [
                        _vm._v("Proibir acesso fora do horário de expediente"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("h5", { staticClass: "mb-1" }, [_vm._v("Adicionar Intervalos")]),
      _vm._l(_vm.employee.office_hour.breaks, function (brk, i) {
        return _c("div", { key: i, staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/4 w-full mt-2" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Dia da Semana"),
              ]),
              _c("v-select", {
                staticClass: "vue_select_drop_size_200",
                attrs: {
                  appendToBody: "",
                  clearable: true,
                  reduce: (option) => option.value,
                  placeholder: "Selecione",
                  options: [
                    { value: 1, label: "Segunda Feira" },
                    { value: 2, label: "Terça Feira" },
                    { value: 3, label: "Quarta Feira" },
                    { value: 4, label: "Quinta Feira" },
                    { value: 5, label: "Sexta Feira" },
                    { value: 6, label: "Sábado" },
                  ],
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: brk.weekday,
                  callback: function ($$v) {
                    _vm.$set(brk, "weekday", $$v)
                  },
                  expression: "brk.weekday",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/4 w-full mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { type: "time", label: "Início" },
                model: {
                  value: brk.start_time,
                  callback: function ($$v) {
                    _vm.$set(brk, "start_time", $$v)
                  },
                  expression: "brk.start_time",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("start_time"),
                      expression: "errors.has('start_time')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("start_time")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/4 w-full mt-2" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { type: "time", label: "Fim" },
                model: {
                  value: brk.end_time,
                  callback: function ($$v) {
                    _vm.$set(brk, "end_time", $$v)
                  },
                  expression: "brk.end_time",
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("end_time"),
                      expression: "errors.has('end_time')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("end_time")))]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/4 w-full mt-0" },
            [
              _c(
                "vx-tooltip",
                {
                  staticClass: "h-8 w-8 mt-8",
                  attrs: {
                    text: i ? "Remover Intervalo" : "Adicionar Intervalo",
                    position: "top",
                  },
                },
                [
                  i === 0
                    ? _c("feather-icon", {
                        staticClass: "cursor-pointer hover:text-primary flex",
                        attrs: {
                          icon: "PlusCircleIcon",
                          svgClasses: "w-8 h-8",
                        },
                        on: { click: _vm.addField },
                      })
                    : _c("feather-icon", {
                        staticClass:
                          "text-danger cursor-pointer hover:text-primary",
                        attrs: {
                          icon: "MinusCircleIcon",
                          svgClasses: "w-8 h-8",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeField(i)
                          },
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-2/3 w-full" },
          [
            _c(
              "vs-alert",
              {
                staticClass: "mt-2 mb-4",
                attrs: {
                  color: "primary",
                  title: "Orientações:",
                  active: true,
                  "icon-pack": "feather",
                },
              },
              [
                _c("small", [
                  _vm._v(
                    "Defina ao lado se você deseja impedir os agendamentos fora dos horários de trabalho deste funcionário ou somente ser avisado quando isto ocorrer."
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-switch",
                {
                  staticClass: "ml-auto mt-4",
                  model: {
                    value: _vm.employee.office_hour.block_time,
                    callback: function ($$v) {
                      _vm.$set(_vm.employee.office_hour, "block_time", $$v)
                    },
                    expression: "employee.office_hour.block_time",
                  },
                },
                [
                  _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                    _vm._v("Impedir Agendamentos"),
                  ]),
                  _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                    _vm._v("Somente Avisar"),
                  ]),
                ]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4 mt-2",
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.save },
                },
                [_vm._v("Alterar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }