<template>
  <div id="student-documents">

    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <vs-popup class="popup60" :fullscreen="false" :title="showPdfTitle" :active.sync="popupCommission">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Filtro 1</label>
          <v-select class="vue_select_drop_size_90" v-model="commission.type" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: 'Por Contrato', value: 'by_contract'},
              {label: 'Por Parcela', value: 'by_installment'}]" placeholder="TIPO" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <label class="vs-input--label">Filtro 2</label>
          <v-select class="vue_select_drop_size_90" v-model="commission.type2" :reduce="option => option.value" :clearable="false"
            :options="[
              {label: 'Lançado(a)', value: 'by_register'},
              {label: 'Pago(a)', value: 'by_payment'}]" placeholder="TIPO" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupCommission = false">Cancelar</vs-button>
            <vs-button class="ml-4" @click="generateReport()">Gerar Documento</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <div class="flex-grow">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="date" @change="checkPeriod" v-model="firstPeriod" label="Data Inicial" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <vs-input type="date" @change="checkPeriod" v-model="lastPeriod" label="Data Final" />
          </div>
          <div class="vx-col md:w-1/3 w-full">
            <label class="vs-input--label">Ordem</label>
            <v-select :clearable="false" v-model="orderBy" :reduce="option => option.value"
              :options="[
                {value: 'created_at', label: 'Emissão'}
              ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>
        <span class="text-danger text-sm" v-show="periodError">{{ periodErrorMessage }}</span>
        </div>
        <vs-input label="Nome do Relatório" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-0 ml-4" :class="{'mb-6': periodError}" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida ..." />
      </div>

      <h3>Relatórios</h3>
      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table noDataText="" class="bordered" :data="preDocumentsFiltered" hoverFlat style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px;"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px;"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td>

                <div class="flex flex-wrap items-center">
                  <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                    <svg @click="print(doc, 'PDF')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="svg-hover h-5 w-5 cursor-pointer" aria-hidden="true" focusable="false" width="0.86em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792"><path d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" /></svg>
                  </vx-tooltip>
                  <vx-tooltip text="Relatório Excel" position="top" class="mt-2 ml-2">
                    <svg @click="print(doc, 'EXCEL')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="svg-hover h-5 w-5 cursor-pointer" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" /></svg>
                  </vx-tooltip>
                </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-0">
          <vs-table noDataText="" class="bordered" :data="preDocumentsFilteredCol2" hoverFlat style="overflow: -webkit-paged-y;">
            <template slot="thead">
              <vs-th style="font-size: 11px;"><span>TÍTULO</span></vs-th>
              <vs-th style="font-size: 11px;"><span>GERAR</span></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="(doc, i) in data" :key="i" style="font-size: 12px; opacity: 1 !important">
                <vs-td style="text-align:left"><span class="cursor-default font-semibold">{{ doc.title }}</span></vs-td>
                <vs-td>
                  <div class="flex flex-wrap items-center">
                    <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                      <svg @click="print(doc, 'PDF')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer" aria-hidden="true" focusable="false" width="0.86em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792"><path d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" /></svg>
                    </vx-tooltip>
                    <vx-tooltip text="Relatório Excel" position="top" class="mt-2 ml-2">
                      <svg @click="print(doc, 'EXCEL')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        class="svg-hover h-5 w-5 cursor-pointer" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" /></svg>
                    </vx-tooltip>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  components: {
    vSelect
  },
  props: {
    employee: { type: Object, required: true }
  },
  data () {
    return {
      typeReport: 'PDF',

      periodErrorMessage: 'O período é obrigatório.',
      periodError: true,
      firstPeriod: '',
      lastPeriod: '',
      orderBy: 'created_at',

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      popupCommission: false,
      popupReceipt: false,

      studentOptions: [],
      commission: {
        type: 'by_contract',
        type2: 'by_register',
        employees: [this.employee.id]
      },

      searchQuery: '',
      preDocumentsFiltered: [],
      preDocuments: [{ value: 1, title: 'Comissões' }],
      preDocumentsFilteredCol2: [],
      preDocumentsCol2: []
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    }
  },
  methods: {
    checkPeriod () {
      if (moment(this.firstPeriod).year() > 1000 && moment(this.lastPeriod).year() > 1000) {
        const difDays = moment(this.lastPeriod).diff(moment(this.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.periodErrorMessage = 'O período inicial deve ser anterior ao final.'
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.periodError = true
          this.periodErrorMessage = 'O período não pode ser maior que 5 anos.'
          return
        }
        this.periodError = false
      } else if (!this.firstPeriod && !this.lastPeriod) {
        this.periodError = true
        this.periodErrorMessage = 'O período é obrigatório.'
      }
    },
    async print (doc, type) {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_report_protocols_emitidos')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('generate'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        this.$nextTick(() => { this.$vs.loading.close() })
        return
      }

      if (this.periodError) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Cheque o Período!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      try {
        this.type = type
        this.$vs.loading()

        this.showPdfTitle = 'Relatório de Comissões Por data de Contratação de Contrato.'
        this.popupCommission = true

        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Relatório.xlsx'
      link.click()
    },
    async generateReport () {
      this.$vs.loading()
      try {
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/commission/report`, {
          firstPeriod: this.firstPeriod,
          lastPeriod: this.lastPeriod,
          orderBy: this.orderBy,
          format: this.type,
          payload: this.commission
        }, { responseType: this.type === 'EXCEL' ? 'arraybuffer' : null })

        if (this.type === 'PDF') {
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        } else if (this.type === 'EXCEL') {
          const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          this.makeBlobDoc(blob)
        }
        this.popupCommission = false

        this.$nextTick(() => {
          this.$vs.loading.close()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$vs.loading.close()
        })
        this.$vs.notify({
          time: 5000,
          title: 'AVISO',
          text: error,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult (searchVal) {
      this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments.filter(a => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1)))
      this.preDocumentsFilteredCol2 = JSON.parse(JSON.stringify(this.preDocumentsCol2.filter(a => a.title.toUpperCase().indexOf(searchVal.toUpperCase()) > -1)))
    }
  },
  created () {
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)

    this.preDocumentsFiltered = JSON.parse(JSON.stringify(this.preDocuments))
    this.preDocumentsFilteredCol2 = JSON.parse(JSON.stringify(this.preDocumentsCol2))
  }
}
</script>

<style lang="scss">
  .con-vs-popup .vs-popup {
    width: 100%;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .popup50 .vs-popup {
    width: 50% !important;
  }
  .popup60 .vs-popup {
    width: 60% !important;
  }
  .vue_select_drop_size_90 .vs__dropdown-menu {
    max-height: 90px;
  }
  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
  #student-documents th {
    background-color: #b4b4b4;
  }
  #student-documents th .vs-table-text {
    color: #158482;
    cursor: default;
  }
  #student-documents .vs-con-table .vs-con-tbody {
    border: 1px solid #c0c0c0;
  }
  .svg-hover:hover {
    fill: #158482;
  }
  .svg-hover {
    fill: #626262;
  }
  #student-documents td {
    border: 1px solid #c0c0c0;
    line-height: 1;
  }
#student-documents td:hover {
  border-bottom: 1px solid #353434;
}
#student-documents td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#student-documents .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#student-documents .vs-table--thead {
  border: 1px solid transparent;
}
// .icon {
//   font-size: 16px;
// }
#student-documents .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#student-documents .ps__scrollbar-y-rail {
  z-index: 99999;
}
.vs-tooltip {
  z-index: 99999;
}
.under:hover {
  text-decoration: underline;
}
</style>
