export const modules = [
  {
    name: 'Atendimento',
    items: [
      {
        name: 'Aluno',
        resources: [
          {
            name: 'Contrato',
            permissions: [
              {
                name: 'Excluir',
                value: 'attendance:student:contract:destroy'
              }
            ]
          },
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'attendance:student:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Documentos alunos',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'attendance:student_document:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Documentos Cfc',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'attendance:cfc_document:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Veículos',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'attendance:vehicle:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Simulado',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'attendance:test_simulation:view'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Agenda',
    items: [
      {
        name: 'Aula prática',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:practical_class'
              }
            ]
          }
        ]
      },
      {
        name: 'Aula teórica',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:theoretical_class:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Compromissos',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:commitment:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Exame médico',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:medical_exam:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Exame psicotécnico',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:psychotechnical_exam:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Exame teórico',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:theoretical_exam:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Exame prático',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:practical_exam:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Configuração',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'schedule:setting:view'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Financeiro',
    items: [
      {
        name: 'Caixa Geral',
        resources: [
          {
            name: 'Receita',
            permissions: [
              {
                name: 'Criar',
                value: 'financial:general_cashier:credit:create'
              },
              {
                name: 'Editar',
                value: 'financial:general_cashier:credit:edit'
              },
              {
                name: 'Estornar',
                value: 'financial:general_cashier:credit:reverse'
              },
              {
                name: 'Excluir',
                value: 'financial:general_cashier:credit:destroy'
              }
            ]
          },
          {
            name: 'Despesa',
            permissions: [
              {
                name: 'Criar',
                value: 'financial:general_cashier:debit:create'
              },
              {
                name: 'Editar',
                value: 'financial:general_cashier:debit:edit'
              },
              {
                name: 'Estornar',
                value: 'financial:general_cashier:debit:reverse'
              },
              {
                name: 'Excluir',
                value: 'financial:general_cashier:debit:destroy'
              }
            ]
          }, {
            name: 'Outros',
            permissions: [
              {
                name: 'Fazer transferência',
                value: 'financial:general_cashier:transfer:create'
              }
            ]
          }
        ]
      },
      {
        name: 'Meu caixa',
        resources: [
          {
            name: 'Operação no caixa',
            permissions: [
              {
                name: 'Registrar',
                value: 'financial:my_cashier:credit:create'
              },
              {
                name: 'Excluir Lançamento',
                value: 'financial:my_cashier:credit:destroy'
              }
            ]
          }
        ]
      },
      {
        name: 'Remessa Boleto',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:delivery_bankslip:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Contas',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:account:view'
              }
            ]
          }
        ]
      },
      {
        name: 'OS Interna',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:service_order:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Recibos',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:receipt:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Recebível de cartão',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:card_receipt:view'
              }
            ]
          }
        ]
      },
      {
        name: 'Configuração',
        resources: [
          {
            name: 'Configuração do caixa',
            permissions: [
              {
                name: 'Permitir',
                value: 'financial:setting:view'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Relatório',
    items: [
      {
        name: 'Geral',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'report:student:view'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Mala direta',
    items: [
      {
        name: 'Geral',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'mail_marketing:cnh:send'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Cadastro',
    items: [
      {
        name: 'Geral',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'register:employee:view' // coloquei assim porque depois vou quebrar em varias permissoes
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Configurações',
    items: [
      {
        name: 'Geral',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'configuration'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Auditoria',
    items: [
      {
        name: 'Auditoria',
        resources: [
          {
            name: 'Acesso total',
            permissions: [
              {
                name: 'Permitir',
                value: 'audit:view'
              }
            ]
          }
        ]
      }
    ]
  }
]
