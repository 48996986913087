<template>
  <div>
  <vs-popup
      :fullscreen="false"
      title="Gerenciar grupos"
      :active.sync="popupListGroup"
    >
    <list-group />
  </vs-popup>
   <div class="inline-flex w-full">
     <div class="w-full">
      <label class="vs-input--label">Grupo de acesso</label>
      <v-select
        v-model="group"
        multiple
        :clearable="false"
        :options="options"
        :reduce="(option) => option.value"
        data-vv-as="Grupo"
        v-validate.initial="'required'"
        :disabled="disabled"
        name="single"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        class="sigselect"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
    </div>
    <div class="flex items-end">
        <vs-button icon="edit" class="sigbutton" @click="popupListGroup = true" :disabled="disabled"/>
    </div>
   </div>
   <span class="text-danger text-sm" v-show="errors.has('single')">{{
        errors.first('single')
      }}</span>
  </div>
</template>

<script>
import ListGroup from './ListGroup.vue'

export default {
  components:{
    ListGroup
  },
  props: {
    disabled:{},
    value:{},
    useId:{}
  },
  data () {
    return {
      popupListGroup: false
    }
  },
  computed: {
    group:{
      get () {
        if (this.useId) return this.value
        else if (this.value && Array.isArray(this.value))  return this.value.map(e => e.id)
      },
      set (value) {
        if (this.useId) this.$emit('input', value)
        else this.$emit('input', this.allGroups.filter(e => value.includes(e.id)))
      }
    },
    options () {
      return this.$store.getters['group/optionsForSelect']
    },
    allGroups () {
      return this.$store.state.group.groups
    }
  }
}
</script>

<style>
.sigselect > .vs__dropdown-toggle{
  border-right: none !important;
  border-radius: 6px 0px 0px 6px;
  border-color: rgba(var(--vs-primary),1);
}
.sigbutton {
  border-left: none;
  border-radius: 0px 6px 6px 0px;
}

</style>
