var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-edit-access-permission-tab" } },
    [
      _vm.systemAccess
        ? _c(
            "div",
            [
              _c(
                "vs-tabs",
                {
                  attrs: {
                    id: "access-permission-tab",
                    position: "left",
                    color: "primary",
                  },
                },
                [
                  _c(
                    "vs-tab",
                    { attrs: { label: "Atendimento" } },
                    [
                      _c("atendimento-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Agendas" } },
                    [
                      _c("schedule-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Financeiro" } },
                    [
                      _c("financial-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Relatórios" } },
                    [
                      _c("report-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Mala Direta" } },
                    [
                      _c("mail-marketing-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-tab",
                    { attrs: { label: "Cadastros" } },
                    [
                      _c("registration-tab", {
                        attrs: {
                          permissions: _vm.permissions,
                          employee: _vm.employee,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.systemAccess
        ? _c(
            "vs-alert",
            {
              staticClass: "mt-2 mb-4",
              attrs: {
                color: "primary",
                title: "Funcionário sem acesso ao sistema:",
                active: !_vm.systemAccess,
                "icon-pack": "feather",
              },
            },
            [
              _c("p", [_vm._v("Este funcionário não tem acesso ao sistema.")]),
              _c("p", [
                _vm._v(
                  "Para definir as permissões de acesso a ele, primeiramente conceda acesso ao sistema"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "e depois retorne a esta página para configurar as permissões."
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }