<template>
  <div class="vx-col">
    <vs-input
      class="w-full"
      data-vv-delay="500"
      label="Nome"
      name="name"
      v-model="name"
      data-vv-as="Nome"
      v-validate="'required|min:3'"
      :danger-text="errors.first('name')"
      :danger="errors.has('name')"
      :success="!errors.has('name')"
    />
    <vs-divider />
    <div class="flex justify-end">
      <vs-button @click="save">Salvar</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value:{
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    group () {
      return this.value || {}
    },
    name: {
      get () {
        return this.group.name
      },
      set (value) {
        this.group.name = value
        this.$emit('input', this.group)
      }
    }
  },
  methods: {
    async save () {
      const valid = await this.$validator.validateAll()
      if (valid) {
        this.$vs.loading()
        this.$store.dispatch('group/save', this.group).then(() => {
          this.$emit('saved')
          this.mixinThen()
        }).catch(this.mixinCatch).finally(this.mixinFinally)
      }
    }
  }
}
</script>
