var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: false,
            title: "Gerenciar grupos",
            active: _vm.popupListGroup,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupListGroup = $event
            },
          },
        },
        [_c("list-group")],
        1
      ),
      _c("div", { staticClass: "inline-flex w-full" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Grupo de acesso"),
            ]),
            _c("v-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "sigselect",
              attrs: {
                multiple: "",
                clearable: false,
                options: _vm.options,
                reduce: (option) => option.value,
                "data-vv-as": "Grupo",
                disabled: _vm.disabled,
                name: "single",
                dir: _vm.$vs.rtl ? "rtl" : "ltr",
              },
              scopedSlots: _vm._u([
                {
                  key: "no-options",
                  fn: function ({}) {
                    return [_vm._v(" Nenhum resultado encontrado. ")]
                  },
                },
              ]),
              model: {
                value: _vm.group,
                callback: function ($$v) {
                  _vm.group = $$v
                },
                expression: "group",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex items-end" },
          [
            _c("vs-button", {
              staticClass: "sigbutton",
              attrs: { icon: "edit", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  _vm.popupListGroup = true
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("single"),
              expression: "errors.has('single')",
            },
          ],
          staticClass: "text-danger text-sm",
        },
        [_vm._v(_vm._s(_vm.errors.first("single")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }